<template>
  <div class="box">
    <div class="left leftDetail">
      <img class="leftBg" v-if="!id" src="../assets/images/Inspection.png">
      <img class="leftBg" v-if="id == 1" src="../assets/images/Inspection1.png">
      <img class="leftBg" v-if="id == 2" src="../assets/images/Inspection2.png">
      <img class="leftBg" v-if="id == 3" src="../assets/images/Inspection3.png">
      <img class="leftBg" v-if="id == 4" src="../assets/images/Inspection4.png">
      <img class="leftBg" v-if="id == 5" src="../assets/images/Inspection5.png">
      <img class="leftBg" v-if="id == 6" src="../assets/images/Inspection6.png">
      <img class="leftBg" v-if="id == 7" src="../assets/images/Inspection7.png">
      <img class="leftBg" v-if="id == 8" src="../assets/images/Inspection8.png">
      <div class="leftImg">
        <navHead />
        <div class="leftTxt" v-if="!id">
          Inspection
        </div>
        <div class="leftTxt size38" v-if="id == 1">
          Non-Destructive Testing (NDT)
        </div>
        <div class="leftTxt size38" v-if="id == 2">
          2nd And 3rd Party Inspections
        </div>
        <div class="leftTxt size38" v-if="id == 3">
          Welding Inspection
        </div>
        <div class="leftTxt size38" v-if="id == 4">
          E&i And Mechanical Inspection
        </div>
        <div class="leftTxt size38" v-if="id == 5">
          Coating Inspection
        </div>
        <div class="leftTxt size38" v-if="id == 6">
          Industrial Products: Inspection and Quality Surveillance
        </div>
        <div class="leftTxt size38" v-if="id == 7">
          Inspection of Lifting Slings and Related Ligting Equipment
        </div>
        <div class="leftTxt size38" v-if="id == 8">
          Commercial Inspection
        </div>
      </div>
    </div>
    <div class="right rightDetail" :class="{'detail' : id}">
      <div class="rightTitle" v-if="!id">LIONOX inspection and testing services employ the most advanced equipment and methods to ensure the best possible outcomes. </div>
      <div class="rightTitle" v-if="id == 1">In line with all international codes and standards we provide a comprehensive inspection and testing service:</div>
      <div class="rightTitle" v-if="id == 2">LIONOX is pleased to offer 2nd and 3rd party inspections and testing for our clients covering a range of requirements that include:</div>
      <div class="rightTitle" v-if="id == 3">We provide a complete and comprehensive welding service that includes:</div>
      <div class="rightTitle" v-if="id == 4">We provide all necessary E&I mechanical inspections and testing for the manufacture and repair of diverse onshore and offshore steel structures.</div>
      <div class="rightTitle" v-if="id == 5">To ensure appropriate protection of steel surfaces from steam, water, salt, and oil damage, and to ensure protection from other forms of corrosive harm, we provide thorough coating inspection and detailed consultancy for process piping, long-distance transport pipes, steel structures, pressure vessels and similar equipment.</div>
      <div class="rightTitle" v-if="id == 6">Typical industrial products that fall under our inspection and quality surveillance service include:</div>
      <div class="rightTitle" v-if="id == 7">All lifting slings and related equipment must have valid certification (typically of 6 months validity) before using.  LIONOX provides inspection and testing services and provides for all relevant certification issued by a range of certification bodies including:</div>
      <div class="rightTitle" v-if="id == 8">LIONOX provides inspection and testing of products produced in China as required by our client’s needs and relating to all appropriate standards and codes.  We provide full inspection reports, with photographic detail and comments, prepared to strict deadlines.</div>
      <div class="rightUl" v-if="!id">
        <router-link to="/Inspection?id=1">Non-Destructive Testing (NDT)</router-link>
        <router-link to="/Inspection?id=2">2nd And 3rd Party Inspections</router-link>
        <a>Quality Assurance & Quality Control</a>
        <router-link to="/Inspection?id=3">Welding Inspection</router-link>
        <router-link to="/Inspection?id=4">E&i And Mechanical Inspection</router-link>
        <router-link to="/Inspection?id=5">Coating Inspection</router-link>
        <router-link to="/Inspection?id=6">Industrial Products: Inspection and Quality Surveillance</router-link>
        <router-link to="/Inspection?id=7">Inspection of Lifting Slings and Related Ligting Equipment</router-link>
        <router-link to="/Inspection?id=8">Commercial Inspection</router-link>
      </div>
      <a class="download" v-if="!id" target="_blank" href="http://lionox.co/cpanel/Lionox_Website_2021.5.24.pdf">Please click here for all corresponding certificates. </a>
      <div class="ul" v-if="id == 1">
        <p>● Ultrasonic testing<span>(UT)</span></p>
        <p>● Radiographic testing<span>(RT)</span></p>
        <p>● Magnetic testing<span>(MT)</span></p>
        <p>● Penetration testing<span>(PT)</span></p>
        <p>● Auto ultrasonic testing<span>(AUT)</span></p>
        <p>● Phase array ultrasonic testing<span>(PAUT)</span></p>
        <p>● Time of flight diffraction<span>(TOFD)</span></p>
      </div>
      <div class="ul" v-if="id == 2">
        <p>● All aspects of offshore and onshore engineering</p>
        <p>● Industrial products</p>
        <p>● Commodities</p>
        <p>● Mining equipment</p>
        <p>● Oil and gas facilities</p>
        <p>● Ship building</p>
      </div>
      <div class="ul" v-if="id == 3">
        <p>●  Document preparation</p>
        <p>●  Training</p>
        <p>●  Consultancy</p>
        <p>●  Inspection</p>
        <p>●  Supervision</p>
        <p>●  Quality analysis</p>
      </div>
      <div class="ul" v-if="id == 6">
        <p>●  Assorted pipelines</p>
        <p>●  Shaped steel</p>
        <p>●  Steel plates</p>
        <p>●  H beams</p>
        <p>●  Fittings</p>
        <p>●  Flanges</p>
        <p>●  Bolts and nuts</p>
      </div>
      <div class="ul" v-if="id == 7">
        <p>●  Det Norske Veritas</p>
        <p>●  Bureau Veritas</p>
        <p>●  China Classification Society</p>
      </div>
      <div class="goBack" @click="goBack" v-if="id">
        <span>Back to previous page</span>
      </div>
    </div>
  </div>
</template>

<script>

import navHead from '/src/components/navHead'

export default {
  name: 'Inspection',
  components: {
    navHead
  },
  data(){
    return{
      id: null
    }
  },
  mounted(){
    this.id = this.$route.query.id || null
  },
  methods:{
    goBack(){
      this.$router.go(-1)
    }
  }
}
</script>
